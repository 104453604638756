import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStaticFilters } from './actions/filter-action';
import {
    eliminateEmptyArrays,
    replaceKeysWithDataNames,
} from '@/scripts/functions';
import { Attributes } from '@/constants/attributes-list';

interface FilterState {
    loading: boolean;
    error: string | null;
    productFilter: { [key: string]: string[] };
    isProductFilter: boolean;
    isHeaderFilter: boolean;
    resetFilters: boolean;
    minPrice: number;
    maxPrice: number;
    minDiscount: number;
    maxDiscount: number;
    isPriceFilterApplied: boolean;
    isDiscountApplied: boolean;
    paramKeys: any;
    isLoading: boolean;
    staticFilterData: [];
    status: string;
    filterData: [];
    dynamicFiltersData: [];
    applyFilters: boolean;
    firstTimeUser: boolean;
    firstBrandSlug: string;
    isExpanded: boolean;
}

interface SetDynamicFilterPayload {
    key: string;
    values: string[];
}

const initialState: FilterState = {
    loading: false,
    error: null,
    productFilter: {},
    isProductFilter: false,
    isHeaderFilter: false,
    resetFilters: false,
    minPrice: 1,
    maxPrice: 20000,
    minDiscount: 0,
    maxDiscount: 100,
    isPriceFilterApplied: false,
    isDiscountApplied: false,
    paramKeys: {},
    isLoading: false,
    staticFilterData: [],
    filterData: [],
    status: '',
    dynamicFiltersData: [],
    applyFilters: false,
    firstTimeUser: false,
    firstBrandSlug: '',
    isExpanded: false,
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setIsExpanded(state, action) {
            state.isExpanded = action.payload;
        },
        setFirstBrandSlug(state, action) {
            state.firstBrandSlug = action.payload;
        },
        setPriceFilter(state, action) {
            state.minPrice = action.payload?.[0];
            state.maxPrice = action.payload?.[1];
        },
        setIsPriceFilter(state, action) {
            state.isPriceFilterApplied = action.payload;
        },
        setDiscountFilter(state, action) {
            state.minDiscount = action.payload?.[0];
            state.maxDiscount = action.payload?.[1];
        },
        setIsDiscountFilter(state, action) {
            state.isDiscountApplied = action.payload;
        },
        setHeaderFilter(state, action) {
            state.isHeaderFilter = action.payload;
        },
        setDynamicFilter: (
            state,
            action: PayloadAction<SetDynamicFilterPayload>
        ) => {
            if (action.payload.values.length > 0) {
                state.productFilter[action.payload.key] = action.payload.values;
            } else {
                delete state.productFilter[action.payload.key];
            }
            state.isProductFilter = true;
        },
        setDynamicFilterFromURL: (state, action) => {
            state.productFilter = action.payload;
            state.isProductFilter = true;
        },
        setClearProductFilter: (state) => {
            state.productFilter = {};
            state.isPriceFilterApplied = false;
            state.isDiscountApplied = false;
            state.isProductFilter = false;
            state.isExpanded = false;
        },
        setParamKeys: (state, action) => {
            state.paramKeys[action.payload] = '';
        },
        setResetFilters: (state) => {
            state.resetFilters = true;
        },
        setApplyFilters: (state, action) => {
            state.applyFilters = action.payload;
        },
        setFirstTimeUser: (state, action) => {
            state.firstTimeUser = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getStaticFilters.fulfilled, (state, action) => {
            state.isLoading = false;
            state.staticFilterData = action?.payload?.data?.data;

            const dynamicFiltersData =
                action?.payload?.data?.data?.dynamicFilters;
            state.filterData = dynamicFiltersData; // dynamic filter objects stored
            const newData = eliminateEmptyArrays(dynamicFiltersData); // removed empty values
            const replacedData = replaceKeysWithDataNames(newData, Attributes); // converting key names
            state.dynamicFiltersData = replacedData;
            state.status = 'fulfilled';
        });
    },
});

export const {
    setIsPriceFilter,
    setIsDiscountFilter,
    setDynamicFilterFromURL,
    setParamKeys,
    setDynamicFilter,
    setClearProductFilter,
    setHeaderFilter,
    setPriceFilter,
    setDiscountFilter,
    setResetFilters,
    setApplyFilters,
    setFirstTimeUser,
    setFirstBrandSlug,
    setIsExpanded,
} = filterSlice.actions;

export default filterSlice.reducer;
