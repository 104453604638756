export const Attributes = {
    Brand: 'brand',
    Shade: 'shade',
    ProductForm: 'productForm',
    PackageType: 'packageType',
    ProductTags: 'productTags',
    SizeInKG: 'kg',
    SizeInGM: 'gm',
    SizeInL: 'litre',
    SizeInML: 'ml',
    SizeInUnit: 'unit',
    SizeInTablet: 'tablet',
    SizeInCapsule: 'capsule',
    Ingredients: 'ingredients',
    Dosage: 'dosage',
    Gender: 'gender',
    AgeGroup: 'ageGroup',
    HairType: 'hairType',
    SkinType: 'skinType',
    SkinTone: 'skinTone',
    Occasion: 'occasion',
    Finish: 'finish',
    Concern: 'concern',
    Spf: 'spf',
    Coverage: 'coverage',
    FragranceFamily: 'fragranceFamily',
    Uses: 'uses',
    OriginCountry: 'originCountry',
    Preference: 'preference',
    Conscious: 'conscious',
    CommonSideEffects: 'commonSideEffects',
    AlcoholInteraction: 'alcoholInteraction',
    PregnancyInteraction: 'pregnancyInteraction',
    LactationInteraction: 'lactationInteraction',
    DrivingInteraction: 'drivingInteraction',
    KidneyInteraction: 'kidneyInteraction',
    LiverInteraction: 'liverInteraction',
    MedicineType: 'medicineType',
    Storage: 'storage',
    ProductFormQuantity: 'quantity',
    FactBox: 'factBox',
    Packaging: 'packaging',
    Benefits: 'benefits',
    SafetyAdvice: 'safetyAdvice',
    ProductHighlights: 'productHighlights',
    ProductSpecifications: 'productSpecifications',
    ManufacturerAddress: 'manufacturerAddress',
    ManufacturedBy: 'manufacturedBy',
    ImportedBy: 'importedBy',
    AboutBrand: 'aboutBrand',
    AllergenInformation: 'allergenInformation',
    Features: 'features',
    GoodToKnow: 'goodToKnow',
    IdealFor: 'idealFor',
    QuickTips: 'quickTips',
    PrescriptionRequired: 'prescriptionRequired',
    IfMiss: 'ifMiss',
    Formulation: 'formulation',
    AreaOfApplication: 'areaOfApplication',
    CompatibleWith: 'compatibleWith',
    FragranceNotes: 'fragranceNotes',
    PackSize: 'packSize',
    Flavour: 'flavour',
};
