import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    getCategoryData,
    getThirdCategoryDataByBrandID,
} from './actions/category-action';

interface Category {
    _id: string;
    name: string;
    slug: string;
}

interface CategoryIdPayload {
    firstCategoryId?: any | null;
    secondCategoryId?: any | null;
    thirdCategoryId?: any;
}

interface CategoryState {
    categories: Category[];
    brandCategories: Category[];
    loading: boolean;
    search: string;
    error: string | null;
    categoryId: CategoryIdPayload;
    thirdCategoryId: any;
    thirdCategorySlugs: any;
    firstCategorySlug: any;
    secondCategorySlug: any;
    thirdCategorySlugString: any;
    isFirstCategory: any;
    isSecondCategory: any;
    isThirdCategory: any;
}

const initialState: CategoryState = {
    categories: [],
    brandCategories: [],
    loading: false,
    error: null,
    search: '',
    categoryId: {
        firstCategoryId: null,
        secondCategoryId: null,
        thirdCategoryId: null,
    },
    thirdCategoryId: null,
    thirdCategorySlugs: [],
    firstCategorySlug: null,
    secondCategorySlug: null,
    thirdCategorySlugString: null,
    isFirstCategory: false,
    isSecondCategory: false,
    isThirdCategory: false,
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        SetIsFirstCategory(state, action) {
            state.isFirstCategory = action.payload;
        },

        SetIsSecondCategory(state, action) {
            state.isSecondCategory = action.payload;
        },

        SetIsThirdCategory(state, action) {
            state.isThirdCategory = action.payload;
        },

        SetCategorySearch: (state, { payload }) => {
            state.search = payload.value;
        },
        fetchCategoriesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchCategoriesSuccess(state, action: PayloadAction<Category[]>) {
            state.categories = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchCategoriesFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        SetCategoryId(state, action: PayloadAction<CategoryIdPayload>) {
            state.categoryId = {
                firstCategoryId: action.payload.firstCategoryId,
                secondCategoryId: action.payload.secondCategoryId,
                thirdCategoryId: action.payload.thirdCategoryId,
            };
        },
        SetFilterCategoryId(state, action: PayloadAction<CategoryIdPayload>) {
            state.categoryId = {
                firstCategoryId: action.payload.firstCategoryId,
                secondCategoryId: action.payload.secondCategoryId,
                thirdCategoryId: action.payload.thirdCategoryId,
            };
        },

        ClearCategoryId(state) {
            state.categoryId = {
                firstCategoryId: null,
                secondCategoryId: null,
                thirdCategoryId: null,
            };
        },

        SetThirdCategorySlugs(state, action: PayloadAction<any>) {
            state.thirdCategorySlugs = action.payload;
        },

        SetFirstCategorySlug: (state, action: PayloadAction<string>) => {
            state.firstCategorySlug = action.payload;
        },

        SetSecondCategorySlug: (state, action: PayloadAction<string>) => {
            state.secondCategorySlug = action.payload;
        },

        SetThirdCategorySlugString: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.thirdCategorySlugString = action.payload;
        },

        SetClearCategoryFilter: (state) => {
            state.categoryId = {
                firstCategoryId: '',
                secondCategoryId: '',
                thirdCategoryId: '',
            };
            state.thirdCategorySlugString = null;
            state.thirdCategorySlugs = null;
            state.secondCategorySlug = null;
            state.firstCategorySlug = null;
            state.search = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCategoryData.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.categories = payload;
        });
        builder.addCase(
            getThirdCategoryDataByBrandID.fulfilled,
            (state, { payload }) => {
                state.loading = false;
                state.brandCategories = payload;
            }
        );
    },
});

export const {
    SetCategorySearch,
    fetchCategoriesStart,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    SetCategoryId,
    SetFilterCategoryId,
    ClearCategoryId,
    SetThirdCategorySlugs,
    SetFirstCategorySlug,
    SetSecondCategorySlug,
    SetThirdCategorySlugString,
    SetClearCategoryFilter,
    SetIsFirstCategory,
    SetIsSecondCategory,
    SetIsThirdCategory,
} = categorySlice.actions;

export default categorySlice.reducer;
