import { createAsyncThunk } from '@reduxjs/toolkit';
import { postApi } from '../../../scripts/api-services';
import {
    GET_BRAND_ATTRIBUTE_ENDPOINT,
    GET_TOP_BRANDS_LIST,
} from '../../../constants/endpoints/brandEndpoints';

interface BrandDataArgs {
    page?: number | null;
    pageSize?: number | null;
    attribute?: string | null;
    firstCategoryIdToExclude?: string;
}

// get brand according to first category id
export const getBrandData: any = createAsyncThunk(
    'brand/brandData',
    async (reqData: any, { getState }) => {
        try {
            const response = await postApi(
                GET_BRAND_ATTRIBUTE_ENDPOINT,
                reqData
            );
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

// topBrandsHeader
export const topBrandsHeader: any = createAsyncThunk(
    'brand/topBrandsHeader',
    async (reqData: any, { getState }) => {
        try {
            const response = await postApi(GET_TOP_BRANDS_LIST, reqData);
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

// get brand according to first category id
export const getBrandsForHeader: any = createAsyncThunk(
    'brand/getBrandsForHeader',
    async (category: any, { getState }) => {
        try {
            let reqData = {
                attribute: 'brand',
                firstCategoryId: category,
            };
            const response = await postApi(
                GET_BRAND_ATTRIBUTE_ENDPOINT,
                reqData
            );
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);


// get brand according to first category id
export const getBrandsCategoryWise: any = createAsyncThunk(
    'brand/getBrandsCategoryWise',
    async (category: any, { getState }) => {
        try {
            let reqData = {
                attribute: 'brand',
                page:1, 
                pageSize:12,
                firstCategoryId: category,
            };
            const response = await postApi(
                GET_TOP_BRANDS_LIST,
                reqData
            );
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);

// get all brands
export const getAllBrandData = createAsyncThunk(
    'brand/all-brand-data',
    async ({
        page,
        pageSize,
        attribute,
        firstCategoryIdToExclude,
    }: BrandDataArgs) => {
        try {
            let reqData = {
                page: page,
                pageSize: pageSize,
                attribute: attribute,
                firstCategoryIdToExclude,
            };
            const response = await postApi(
                GET_BRAND_ATTRIBUTE_ENDPOINT,
                reqData
            );
            return response.data;
        } catch (error) {
            console.log('Error fetch brand API', error);
            return Promise.reject(error);
        }
    }
);
