import { GET_STATIC_FITLTER } from '@/constants/endpoints/filterEndPoint';
import { postApi } from '@/scripts/api-services';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getStaticFilters: any = createAsyncThunk(
    'filter/getStaticFilters',
    async (reqData: any, { dispatch }) => {
        try {
            const response = await postApi(GET_STATIC_FITLTER, reqData);
            return response;
        } catch (error) {
            console.log('Error fetch  API', error);
            return Promise.reject(error);
        }
    }
);
