import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    getAllBrandData,
    getBrandData,
    getBrandsCategoryWise,
    getBrandsForHeader,
    topBrandsHeader,
} from './actions/brand-action';

interface BrandId {
    isLoading: boolean;
    brand?: string | null;
    status: string | null;
    search: string | null;
    count: number | null;
    brandData: any[];
    allBrandData: [];
    brandId?: object | null;
    brandAttributeSlugs?: any;
    isBrandFilter: boolean;
    currentPage: number;
    brandsForHeader: [];
    topBrandsHeaderData: [];
    brandsCategoryWise:[]
}

const initialState: BrandId = {
    isLoading: false,
    currentPage: 1,
    brandData: [],
    allBrandData: [],
    brandsForHeader: [],
    topBrandsHeaderData: [],
    brandsCategoryWise:[],
    status: '',
    search: '',
    count: 0,
    brand: null,
    brandAttributeSlugs: [],
    isBrandFilter: false,
    brandId: {
        brand: null,
        brandSlug: null,
    },
};

export const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        SetCurrentBrandPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        SetBrandSearch: (state, { payload }) => {
            state['search'] = payload.value;
        },
        SetBrandId(state, action: PayloadAction<string>) {
            state.brand = action.payload;
        },
        SetBrandAttributeSlugs(state, action: PayloadAction<string[]>) {
            state.brandAttributeSlugs = action.payload;
            state.isBrandFilter = true;
        },
        ClearBrandAttributSlugs(state) {
            state.brandAttributeSlugs = [];
            state.isBrandFilter = false;
        },
        SetFilterBrandId(state, action: PayloadAction<any>) {
            state.brandId = {
                brand: action.payload.brand,
                brandSlug: action.payload.brandSlug,
            };
        },
        ClearFilterBrandId(state) {
            state.brandId = {
                brand: null,
                brandSlug: null,
            };
        },
        ClearBrandId(state) {
            state.brand = null;
        },
        ClearBrandData(state) {
            state.brandData = [];
        },
    },

    extraReducers: (builder) => {
        builder.addCase(
            getBrandData.fulfilled,
            (state, { payload }: PayloadAction<any>) => {
                state.isLoading = false;
                // state.brandData = payload?.data?.list;

                if (state.currentPage === 1) {
                    state.brandData = [...payload?.data?.list];
                } else {
                    state.brandData = [
                        ...state.brandData,
                        ...payload?.data?.list,
                    ];
                }

                state.count = payload?.data?.count;
                state.status = 'succeeded';
            }
        );
        builder.addCase(getAllBrandData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.allBrandData = payload?.data?.list;
            state.count = payload?.data?.count;
        });
        builder.addCase(getBrandsForHeader.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.brandsForHeader = payload?.data?.list;
        });
        builder.addCase(topBrandsHeader.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.topBrandsHeaderData = payload?.data;
        });
        builder.addCase(getBrandsCategoryWise.pending, (state, { payload }) => {
            state.isLoading = true;
            state.brandsCategoryWise = [];
        });
        builder.addCase(getBrandsCategoryWise.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.brandsCategoryWise = payload?.data;
        });
    },
});

export const {
    SetCurrentBrandPage,
    ClearBrandAttributSlugs,
    SetBrandAttributeSlugs,
    SetBrandSearch,
    SetBrandId,
    SetFilterBrandId,
    ClearBrandId,
    ClearBrandData,
    ClearFilterBrandId,
} = brandSlice.actions;

export default brandSlice.reducer;
