import { createAsyncThunk } from '@reduxjs/toolkit';

import { getApi } from '../../../scripts/api-services';
import {
    GET_CATEGORY_BY_BRAND_ID,
    GET_CATEGORY_ENDPOINT,
} from '../../../constants/endpoints/categoryEndpoints';

export const getCategoryData = createAsyncThunk(
    'category/categoryData',
    async () => {
        try {
            const response = await getApi(GET_CATEGORY_ENDPOINT);
            return response?.data;
        } catch (error) {
            console.log('Error fetch category API', error);
            return Promise.reject(error);
        }
    }
);

export const getThirdCategoryDataByBrandID = createAsyncThunk(
    'category/getThirdCategoryDataByBrandID',
    async (brandID: string) => {
        try {
            const response = await getApi(
                `${GET_CATEGORY_BY_BRAND_ID}${brandID}`
            );
            console.log(
                'response get third category by brand id',
                response.data.list
            );
            return response?.data?.list;
        } catch (error) {
            console.log('Error fetch category by brand ID API', error);
            return Promise.reject(error);
        }
    }
);
